/* eslint-disable prettier/prettier */
enum EnvironmentConfig {
  DEV = "dev",
  QA = "qa",
  PROD = "prod",
  SERVER = "server",
  SANDBOX = "sandbox"
}

const matchers = {
  dev: /localhost/,
  qa: /[a-z]?qa+./g,
  sandbox: /[a-z]?sandbox+./g
};

const mapPathMixpanel = {
  [EnvironmentConfig.DEV]: process.env.NEXT_PUBLIC_MIXPANEL_DEV,
  [EnvironmentConfig.QA]: process.env.NEXT_PUBLIC_MIXPANEL_QA,
  [EnvironmentConfig.PROD]: process.env.NEXT_PUBLIC_MIXPANEL_PROD,
  [EnvironmentConfig.SANDBOX]: process.env.NEXT_PUBLIC_MIXPANEL_DEV,
  [EnvironmentConfig.SERVER]: process.env.MIXPANEL_ID
};

const mapPathGraphql = {
  [EnvironmentConfig.DEV]: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT_DEV,
  [EnvironmentConfig.QA]: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT_QA,
  [EnvironmentConfig.PROD]: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT_PROD,
  [EnvironmentConfig.SANDBOX]: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT_SANDBOX,
  [EnvironmentConfig.SERVER]: `${process.env.PROTOCOL}://${process.env.ENDPOINT}/graphql`
};

const mapPathImages = {
  [EnvironmentConfig.DEV]: process.env.NEXT_PUBLIC_IMAGE_ENDPOINT_DEV,
  [EnvironmentConfig.QA]: process.env.NEXT_PUBLIC_IMAGE_ENDPOINT_QA,
  [EnvironmentConfig.PROD]: process.env.NEXT_PUBLIC_IMAGE_ENDPOINT_PROD,
  [EnvironmentConfig.SANDBOX]: process.env.NEXT_PUBLIC_IMAGE_ENDPOINT_SANDBOX,
  [EnvironmentConfig.SERVER]: process.env.IMAGE_ENDPOINT
};

const mapUnleashApiToken = {
  [EnvironmentConfig.DEV]: process.env.NEXT_PUBLIC_UNLEASH_API_TOKEN_DEV,
  [EnvironmentConfig.QA]: process.env.NEXT_PUBLIC_UNLEASH_API_TOKEN_QA,
  [EnvironmentConfig.PROD]: process.env.NEXT_PUBLIC_UNLEASH_API_TOKEN_PROD,
  [EnvironmentConfig.SANDBOX]: process.env.NEXT_PUBLIC_UNLEASH_API_TOKEN_DEV,
  [EnvironmentConfig.SERVER]: ""
};

const createInstance = (() => {
  let instance: {
    currentEnvironment: EnvironmentConfig;
    imagePath: string;
    endpointGraphql: string;
  } | null = null;

  return function getInstance() {
    if (!instance) {
      const currentEnvironment =
        typeof window === "undefined"
          ? EnvironmentConfig.SERVER
          : window.location.hostname.match(matchers.dev)
            ? EnvironmentConfig.DEV
            : window.location.hostname.match(matchers.qa)
              ? EnvironmentConfig.QA
              : window.location.hostname.match(matchers.sandbox)
                ? EnvironmentConfig.SANDBOX
                : EnvironmentConfig.PROD;

      const imagePath = mapPathImages[currentEnvironment];
      const endpointGraphql = mapPathGraphql[currentEnvironment];
      instance = { currentEnvironment, imagePath, endpointGraphql };
    }
    return instance;
  };
})();

const envInstance = createInstance();

export const { currentEnvironment, endpointGraphql, imagePath } = envInstance;

export const mixpanelEnv = mapPathMixpanel[currentEnvironment];

export const unleashApiToken = mapUnleashApiToken[currentEnvironment];

export const unleashEnvironment =
  currentEnvironment === EnvironmentConfig.PROD ? "production" : "development";
